<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Reduced positions</div>
    </template>
    <Toolbar>
      <template #start v-if="!itemsArrayIsEmpty">
        <MultipleDeleteButton :label="`${$t('Delete')} ${selectedItems?.length ? `(${selectedItems?.length} items)`  : ''}`"
                              @click="confirmItemsMultipleDelete"
                              :disabled="dataTableIsLoading || !selectedItems?.length"/>
      </template>
      <template #end>
<!--        <CustomDropdown class="p-mr-2"-->
<!--                        style="min-width: 180px"-->
<!--                        :disabled="dataTableIsLoading"-->
<!--                        v-model="selectedType"-->
<!--                        :options="ReducedPositionTypes"-->
<!--                        filterPlaceholder="Select type"-->
<!--                        @change="changeReducedPositionsType"-->
<!--                        :filter="false"-->
<!--                        :showClear="true">-->
<!--          <template #value="slotProps">-->
<!--            <div v-if="slotProps.value">-->
<!--              {{ slotProps.value.label[$i18n.locale] }}-->
<!--            </div>-->
<!--            <span v-else>Select type</span>-->
<!--          </template>-->
<!--          <template #option="slotProps">-->
<!--            <div v-if="slotProps.option">-->
<!--              {{ slotProps.option.label[$i18n.locale] }}-->
<!--            </div>-->
<!--          </template>-->
<!--        </CustomDropdown>-->
        <SearchInput :changeUrl="false" @search-items="getItems" :disabled="dataTableIsLoading" v-model="searchData" @show-spinner="isLoading = true"/>
<!--        <div class="p-input-icon-left">-->
<!--          <i class="pi pi-search" />-->
<!--          <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--        </div>-->
      </template>
    </Toolbar>

    <Spinner v-if="isLoading"></Spinner>
<!--    <div class="p-formgrid p-grid" v-else-if="!isLoading && !$store.state.tabletLayout">-->
<!--    <div class="p-formgrid p-grid p-mt-4" v-else>-->
<!--      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">-->
    <DesktopDataTable :dataTableIsLoading="dataTableIsLoading"
                      v-else
                      class="p-mt-4"
                      tableName="Reduced positions"
                      :itemsArrayIsEmpty="itemsArrayIsEmpty"
                      :sort="sort"
                      :showSearchInput="false"
                      :actionButtonsCount="actionButtonsCount"
                      @sort-items="sortItems"
                      :headers="[
              // {name: 'code', title: 'Code', width: '12%', sortable: false},
              // {name: 'name', title: 'Name', width: '18%',sortable: false},
              // {name: 'supplier', title: $t('Supplier'), width: '12%', sortable: false},
              // {name: 'qty', title: 'Qty', width: '80px', sortable: true},
              // {name: 'purchase_price', title: 'Purchase price', sortable: false},
              // {name: 'sell_price', title: 'Sell price', sortable: false},
              // {name: 'order', title: '', width: '40px', sortable: false},
              // {name: 'type', title: $t('Type'), sortable: true},
              // {name: 'reason', title: $t('Reason'), sortable: true},
              // {name: 'created_at', title: $t('Created'), width: '14%', sortable: true},

              {name: 'code', title: $t('Code'), sortable: false},
              {name: 'name', title: $t('Name'), sortable: false},
              {name: 'supplier', title: $t('Supplier'), sortable: false},
              {name: 'qty', title: $t('Qty'), sortable: true},
              {name: 'purchase_price', title: 'Buy', sortable: false},
              // {name: 'sell_price', title: 'Sell price', sortable: false},
              {name: 'order', sortable: false},
              {name: 'type', title: $t('Type'), sortable: true},
              {name: 'reason', title: $t('Reason'), sortable: true},
              {name: 'comment', sortable: false},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
      <template v-slot:multiselectHeader>
        <th v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">
          <Checkbox :disabled="allCurrentItemsAreAdded || dataTableIsLoading" @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>
        </th>
      </template>
      <template v-slot:body>
        <tr v-for="(item, index) of items"
            :key="index"
            :class="{'p-highlight': isItemSelected(item.id)}">
          <td :class="{ 'new-item': isItemAdded(item.id) }" v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
            <Checkbox v-if="!isItemAdded(item.id)" :disabled="dataTableIsLoading" :class="{ 'no-pointer':isItemAdded(item.id)}" :value="item.id" v-model="selectedItems"/>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <span v-if="item.position_data?.code">{{ item.position_data.code }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <span v-if="item.position_data">{{ item.position_data.name }}</span>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <span v-if="item.supplier">{{ item.supplier.company_name }}</span>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <div style="position:relative; left:10px">{{ +item.qty - +item.fixed_qty }}</div>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <span v-if="item.position_data?.purchase_price">{{ formatCurrency(item.position_data.purchase_price) }}</span>
            <i v-else class="ti-minus"></i>
          </td>
<!--              <td :class="{ 'new-item': isItemAdded(item.id) }">-->
<!--                <span v-if="item.position_data?.sell_price && +item.position_data?.sell_price">{{ formatCurrency(item.position_data.sell_price) }}</span>-->
<!--                <i v-else class="ti-minus"></i>-->
<!--              </td>-->
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <span v-if="item.order && item.order.number">
              <router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank" :class="{'highlight--link':isItemSelected(item.id)}"><i class="ti-hummer"></i></router-link>
            </span>
            <i v-else class="ti-minus"></i>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
            <span v-if="item.type">{{ ReducedPositionTypes.find(type => type.id === item.type)?.label[$i18n.locale] }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }">
<!--            <span v-if="item.car">-->
<!--              <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank" class="p-d-flex p-flex-wrap" :class="{'highlight&#45;&#45;link':isItemSelected(item.id)}">-->
<!--                <div class="p-mr-1" v-if="item.car && item.car.make">{{ item.car.make.name }}</div>-->
<!--                <div class="p-mr-1" v-if="item.car && item.car.model">{{ item.car.model.name }}, </div>-->
<!--                <div class="p-mr-1">{{ item.car.plate_number }}</div>-->
<!--              </router-link>-->
<!--            </span>-->
<!--            <i v-else class="ti-minus"></i>-->
            <span v-if="item.reason">{{ ReducedPositionReasons.find(reason => reason.id === item.reason)?.label[$i18n.locale] }}</span>
            <router-link v-else-if="item.purchase_invoice_number" :to="{ path: '/purchase-invoices', query: { search: item.purchase_invoice_number } }" target="_blank">
              <span>{{ item.purchase_invoice_number }}</span>
            </router-link>
            <i v-else class="ti-minus"></i>
          </td>
          <td>
            <span v-if="item.comment" @click.prevent href="" v-tooltip.top="item.comment"><i class="ti-comment-alt" ></i></span>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }" class="table-date-line">
            <template v-if="item.created_at || item.creator">
              <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
              <span v-if="item.creator" class="table-date-line">{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
            </template>
            <i v-else class="ti-minus"></i>
          </td>
          <td :class="{ 'new-item': isItemAdded(item.id) }" style="text-align: right">
<!--            <div class="table__actions-column-data">-->
            <AddButton v-if="!isItemAdded(item.id)" @click="addPosition(item)" :disabled="dataTableIsLoading || !positionIsPushed || isItemSelected(item.id) || disableAddPositionButton"/>
            <ReduceButton v-else @click="removePosition(item)" :disabled="dataTableIsLoading || isItemSelected(item.id)"/>
<!--              <DeleteButton/>-->
<!--              <DeleteButton @click="confirmItemDelete(item)"-->
<!--                            v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--            </div>-->
          </td>
        </tr>
      </template>
    </DesktopDataTable>

    <OwnPaginator v-show="!itemsArrayIsEmpty"
                  :rows="pagination.perPage"
                  :currentPage="pagination.currentPage - 1"
                  :totalPages="pagination.pageCount"
                  :totalRecords="pagination.totalCount"
                  :rowsPerPageOptions="[5,10,25,50,100,200]"
                  :pageLinkSize="11"
                  @page="changePage"
                  @change-per-page="changePerPage"
                  :changePaginationRoute="false">
    </OwnPaginator>

    <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
<!--      </div>-->
<!--    </div>-->

    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>

<!--  <ConfirmDeleteModal-->
<!--      :visible="confirmPositionDeleteModal"-->
<!--      @close="closeConfirmPositionDeleteModal"-->
<!--      @delete-item="deletePosition">-->
<!--  </ConfirmDeleteModal>-->

    <ConfirmMultipleDeleteModal
        :showSpinner="dataIsSending"
        :visible="confirmItemsMultipleDeleteModal"
        @close="closeConfirmItemsMultipleDeleteModal"
        @delete-items="deleteMultipleItems">
    </ConfirmMultipleDeleteModal>

</template>

<script>
// import httpClient from '@/services/http.services'
// import Dropdown from '@/components/Dropdown'
import settings from '@/settings'
import constants from '@/constants'
import httpClient from "@/services/http.services";
import formatMixins from '@/mixins/formatMixins'
import AddButton from '@/components/DataTable/AddButton'
import ReduceButton from '@/components/DataTable/ReduceButton'
import ConfirmMultipleDeleteModal from '@/components/ConfirmMultipleDeleteModal'
import ReducedPositionReasons from '@/translations/ReducedPositionReasons'
import ReducedPositionTypes from "@/translations/ReducedPositionTypes";
import datatableMixins from '@/mixins/datatableMixins'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  components: { AddButton, ReduceButton, ConfirmMultipleDeleteModal },
  emits: ['close', 'update-items', 'add-position', 'remove-position', 'items-loaded', 'modal-data-on-clear'],
  name: 'reducedPositionsModal',
  props: {
    visible: Boolean,
    isModal: {
      type: Boolean,
      default: false
    },
    removeReducedPositionId: [Number, null],
    supplierId: [Number, null],
    clearModalData: {
      type: Boolean,
      default: false
    },
    loadReducedPositions: {
      type: Boolean,
      default: false
    },
    positionIsPushed: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dataIsSending: false,
      selectedType: null,
      selectedTypeId: null,
      disableAddPositionButton: false,
      confirmItemsMultipleDeleteModal: false,
      isLoading: true,
      settings,
      submitted: false,
      fieldsExpand: 'position_data,order,car,creator,supplier,purchaseInvoicePosition,purchase_invoice_number',
      apiLink: 'reduced-position/get-reduced-positions',
      searchData: null,
      sort: {
        column: 'id',
        direction: '',
      },
      items: [],
      pagination: {
        totalCount: null,
        pageCount: null,
        currentPage: 1,
        perPage: 25
      },
      addedItems: [],
      selectedItems: [],
      selectAllCheckbox: [],
      constants,
      ReducedPositionReasons,
      ReducedPositionTypes
    }
  },
  watch: {
    // supplierId(value) {
    //   if (value && this.disableAddPositionButton) {
    //     this.disableAddPositionButton = false
    //   }
    // },
    loadReducedPositions(value) {
      if (value) {
        this.getItems()
      }
    },
    clearModalData(boolean) {
      if (boolean) {
        this.selectedType = null
        this.selectedTypeId = null

        this.selectedItems = []
        this.selectAllCheckbox = []
        this.submitted = false

        this.addedItems = []
        this.searchData = null
        this.pagination = {
          totalCount: null,
          pageCount: null,
          currentPage: 1,
          perPage: 25
        }
        this.sort = {
          column: 'id',
          direction: '',
        }
        this.$emit('modal-data-on-clear')
      }
    },
    removeReducedPositionId(positionId) {
      if (!positionId) return false
      const positionIndex = this.addedItems.indexOf(positionId)
      if (positionIndex !== -1) {
        this.addedItems.splice(positionIndex, 1)
      }
      // this.addedItems.splice(this.addedItems.indexOf(positionId), 1)
      // console.log(this.addedItems)
      // this.addedItems = this.addedItems.filter(item => item.id !== positionId)
      // console.log(this.addedItems.filter(item => item.id === positionId))
    },
    // supplierId() {
    //   this.getItems()
    // },
    // visible() {
    //   if (!this.visible) {
    //     this.selectedItems = []
    //     this.selectAllCheckbox = []
    //     this.submitted = false
        // this.pagination = {
        //   totalCount: null,
        //   pageCount: null,
        //   currentPage: 1,
        //   perPage: 25
        // },
        // if (this.searchData.length) {
        //   this.searchData = ''
        //   this.getItems()
        // }
        // this.sort = {
        //   column: 'id',
        //   direction: '',
        // }
      // }
    // },
    selectedItems() {
      this.checkAllItemsAreSelected()
      // this.createSelectedInvoicesData()
    },
    '$store.state.thirdLayerIsOpened'() {
      if (!this.$store.state.thirdLayerIsOpened) {
        if (this.confirmItemsMultipleDeleteModal) {
          this.closeConfirmItemsMultipleDeleteModal()
        }
      }
    },
  },
  methods: {
    changeReducedPositionsType() {
      if (this.selectedTypeId === this.selectedType?.id) return false
      this.selectedTypeId = this.selectedType?.id
      this.getItems()
    },
    addPosition(position) {
      if (!this.positionIsPushed) {
        return false
      }
      // if (!this.supplierId) {
      //   this.disableAddPositionButton = true
      // }
      if (!this.supplierId) {
        this.selectedItems = []
        this.selectAllCheckbox = []
      }

      this.addedItems.push(position.id)

      const positionType = position.type === 1 ? 5 : 7

      this.$emit('add-position', position, positionType)
      this.checkAllItemsAreSelected()
    },
    removePosition(position) {
      const positionIndex = this.addedItems.indexOf(position.id)
      if (positionIndex !== -1) {
        this.addedItems.splice(positionIndex, 1)
        this.$emit('remove-position', position.id)
      }
      this.checkAllItemsAreSelected()
    },
    changePage(data) {
      if (this.pagination.currentPage === data.page + 1) return false
      this.pagination.currentPage = data.page + 1
      this.getItems()
    },
    changePerPage(perPage) {
      if (this.pagination.perPage === perPage) return false
      this.pagination.perPage = perPage
      this.getItems()
    },
    searchItems(searchData) {
      this.searchData = searchData
      this.getItems()
    },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            supplierId: this.supplierId ?? null,
            // typeId: this.selectedTypeId,
            typeId: 1,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            sortColumn: this.sort.column,
            sortDirection: this.sort.direction,
            search: this.searchData,
            expand: this.fieldsExpand,
            show_only_unfixed: true,
          },
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          const arrayIsEmpty = !data?.length

          if (arrayIsEmpty) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
            // this.$emit('positions-loaded')
          } else {
            // this.$emit('disable-request-button', false)
          }
          this.$emit('items-loaded', arrayIsEmpty)
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.checkAllItemsAreSelected()
      }
    },
    async deleteMultipleItems() {
      this.dataIsSending = true
      try {
        const { status } = await httpClient.post('reduced-position/hide-positions', { ids: this.selectedItems })
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife});
          this.searchData = null

          this.getItems()
          this.closeConfirmItemsMultipleDeleteModal()
          this.unselectAllItems()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    confirmItemsMultipleDelete() {
      this.confirmItemsMultipleDeleteModal = true
      this.$store.commit('toggleThirdLayer', true)
    },
    closeConfirmItemsMultipleDeleteModal() {
      this.itemToDelete = {}
      this.confirmItemsMultipleDeleteModal = false
      this.$store.commit('toggleThirdLayer', false)
    },
    checkAllItemsAreSelected() {
      let allItemsAreSelected = true
      this.items?.forEach(item => {
        if (!this.isItemSelected(item.id) && !this.isItemAdded(item.id)) {
          allItemsAreSelected = false
        }
      })

      if (allItemsAreSelected && this.selectedItems.length && !this.allCurrentItemsAreAdded) {
        this.selectAllCheckbox = [0]
      } else {
        this.selectAllCheckbox = []
      }
    },
    selectAllItems() {
      this.items.filter(item => !this.isItemSelected(item.id) && !this.isItemAdded(item.id)).forEach(item => this.selectedItems.push(item.id))
    },
    sortItems(columnName) {
      if (this.sort.column !== columnName) {
        this.sort.direction = ''
      } else {
        this.sort.direction = this.sort.direction === '' ? '-' : '';
      }
      this.sort.column = columnName
      this.getItems()
    },
    close() {
      this.$emit('close')
    }
  },
  computed: {
    allCurrentItemsAreAdded() {
      let result = true
      this.items.forEach(item => {
        if (!this.isItemAdded(item.id)) {
          result = false
        }
      })
      return result
    },
    itemsArrayIsEmpty() {
      return !(this.items && this.items.length)
    },
    actionButtonsCount() {
      // if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 1
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '90%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '82%', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '87%', maxWidth: '1400px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>